import {Injectable} from '@angular/core';
import {HttpService} from '@etop/common';
import {CursorPaging, Filters, MoneyTransactionRrule, Query} from "@etop/models";
import {Shop} from "libs/models/Account";

const MONEY_TRANSACTION_RRULE = {
  'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR': 'Hàng ngày',
  'FREQ=WEEKLY;BYDAY=MO,WE,FR': 'Thứ 2-4-6 hàng tuần',
  'FREQ=WEEKLY;BYDAY=TU,TH': 'Thứ 3-5 hàng tuần',
  'FREQ=WEEKLY;BYDAY=FR': '1 tuần 1 lần vào thứ 6',
  'FREQ=MONTHLY;BYDAY=+2FR,-1FR': '1 tháng 2 lần vào thứ 6',
  'FREQ=MONTHLY;BYDAY=-1FR': '1 tháng 1 lần vào thứ 6',
  'FREQ=MONTHLY;BYDAY=MO,TU,WE,TH,FR;BYSETPOS=-1': '1 tháng 1 lần vào ngày cuối cùng của tháng'
}

@Injectable({
  providedIn: 'root'
})
export class AdminShopApi {

  constructor(
    private http: HttpService
  ) {
  }

  getShop(id: string) {
    return this.http
      .post('api/admin.Shop/GetShop', {id})
      .toPromise()
      .then(res => Shop.shopMap(res));
  }

  getShops(query: AdminShopAPI.GetShopsRequest) {
    return this.http
      .post('api/admin.Shop/GetShops', query)
      .toPromise()
      .then(res => res.shops.map(s => Shop.shopMap(s)));
  }

  getShopsByIDs(ids: string[]) {
    return this.http
      .post('api/admin.Shop/GetShopsByIDs', {ids})
      .toPromise()
      .then(res => res.shops.map(s => Shop.shopMap(s)));
  }

  updateShopMoneyTransactionRrule(id: string, money_transaction_rrule: MoneyTransactionRrule) {
    return this.http
      .post('api/admin.Shop/UpdateShopInfo', {id, money_transaction_rrule})
      .toPromise();
  }

  updateShopPriorityMoneyTransaction(id: string, is_prior_money_transaction: boolean) {
    return this.http
      .post('api/admin.Shop/UpdateShopInfo', {id, is_prior_money_transaction})
      .toPromise();
  }

  getShopSetting(shop_id: string) {
    return this.http
      .post('api/admin.Zalo/GetShopSetting', {shop_id})
      .toPromise();
  }

  updateShopSetting(request: AdminShopAPI.UpdateShopSettingFilter) {
    return this.http
      .post('api/admin.Zalo/UpdateShopSetting', request)
      .toPromise();
  }

  createTemplate(request: AdminShopAPI.CreateTemplate) {
    return this.http
      .post('api/admin.Zalo/CreateTemplate', request)
      .toPromise();
  }

  updateTemplate(request: AdminShopAPI.CreateTemplate) {
    return this.http
      .post('api/admin.Zalo/UpdateTemplate', request)
      .toPromise();
  }

  listTemplates(request: AdminShopAPI.ListTemplatesFilter) {
    return this.http
      .post('api/admin.Zalo/ListTemplates', {filter: request})
      .toPromise().then(res => res?.templates);
  }

  adminListTemplates(request: AdminShopAPI.GetListTemplateRequest) {
    return this.http
      .post('api/admin.Zalo/ListTemplates', request)
      .toPromise().then(res => res);
  }

  listShopOA(request: AdminShopAPI.ListTemplatesFilter) {
    return this.http
      .post('api/admin.Zalo/ListShopOA', {filter: request})
      .toPromise().then(res => res?.accounts);
  }

  createShopApp(request: AdminShopAPI.CreateShopApp) {
    return this.http
      .post('api/admin.Zalo/CreateShopApp', request)
      .toPromise();
  }

  getShopApp(app_id: string, shop_id: string) {
    return this.http
      .post('api/admin.Zalo/GetShopApp', {app_id, shop_id})
      .toPromise().then(res => res);
  }

  deleteShopApp(app_id: string, shop_id: string) {
    return this.http
      .post('api/admin.Zalo/DeleteShopApp', {app_id, shop_id})
      .toPromise();
  }

  listShopApps(request: AdminShopAPI.ListShopAppsFilter) {
    return this.http
      .post('api/admin.Zalo/ListShopApps', {filter: request})
      .toPromise().then(res => res?.apps);
  }

}

export namespace AdminShopAPI {
  export interface GetShopsRequest {
    filter?: GetShopsFilter,
    filters?: Filters;
    paging?: {
      offset?: number;
      limit?: number;
      sort?: string;
    };
  }

  export interface GetShopsFilter {
    name?: string;
    owner_id?: string;
    shop_ids?: string[];
    shop_codes?: string[];
  }

  export interface UpdateShopSettingFilter {
    allow_connect_direct_zalo?: boolean;
    allow_zcc?: boolean;
    allow_zns?: boolean;
    max_oa_connections?: number;
    shop_id: string;
    allow_call_center?: boolean;
    allow_oa_chat?: boolean;
    connection_id?: string;
  }

  export interface CreateTemplate {
    oa_id: string;
    shop_id: string;
    template_id: number;
    type: "unknown" | "text" | "otp" | "table" | "rating" | string;
    is_journey?: boolean;
    token_type?: "unknown" | "token_logistics_7" | "token_logistics_30" | "token_coach_bus_7" | "token_coach_bus_30";
  }

  export interface ListTemplatesFilter {
    oa_id?: string;
    shop_id?: string;
    template_id?: string;
    type?: "unknown" | "text" | "otp" | "table" | "rating";
    name?: string;
  }

  export interface ListShopOAFilter {
    oa_id?: string;
    shop_id?: string;
    name?: string;
  }

  export class GetListTemplateRequest {
    filter?: ListTemplatesFilter;
    paging?: CursorPaging
  }

  export interface ListShopAppsFilter {
    app_id?: string;
    shop_id?: string;
    date_from?: string;
    date_to?: string;
  }

  export interface CreateShopApp {
    app_id?: string | number;
    shop_id?: string;
    oa_secret?: string;
  }

}
